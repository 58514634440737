import Vue from 'vue'
import VueRouter from 'vue-router'
//import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      title: 'のぞみクリニック築地 | 一般内科、Glp-1受容体作動薬、発熱外来、エクソソーム、プラセンタ | 土曜日診療可能',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。',
      keywords:'のぞみクリニック築地,一般内科,GLP-1受容体作動薬,発熱外来,エクソソーム,プラセンタ,土曜日診療可能' ,
    },
    component: () => import('../views/Home')
  },
  {
    path: '/information',
    meta: {
      title: 'お知らせ | のぞみクリニック築地',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。',
      keywords:'のぞみクリニック築地,一般内科,GLP-1受容体作動薬,発熱外来,エクソソーム,プラセンタ,土曜日診療可能'
    },
    component: () => import('../views/Information')
  },
  {
    path: '/infoitem',
    meta: {title: "お知らせ | のぞみクリニック築地",
      description:'',
      keywords:''},
    component: () => import('../views/InfoItem')
  },
  {
    path: '/about',
    name: 'About',
    meta: {title: '当院について | のぞみクリニック築地',
      description:'一般内科として適切な医療を受けるための最初の窓口としての役割を担うため、地域の皆様の“かかりつけ医”となれるよう、親身で丁寧な初期診療を心がけております。',
      keywords:'のぞみクリニック築地,一般内科,GLP-1受容体作動薬,発熱外来,エクソソーム,プラセンタ,土曜日診療可能'},
    component: () => import('../views/About.vue')
  },
  {
    path: '/access',
    meta: {title: 'アクセス｜のぞみクリニック築地',
      description:'東京都中央区築地7-11-5 中銀ベル築地マンシオン2階、東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分',
      keywords:'築地駅より4分,中銀ベル築地,のぞみクリニック築地'},
    component: () => import('../views/Access')
  },
  {
    path: '/question',
    meta: {title: 'よくある質問｜のぞみクリニック築地',
      description:'',
      keywords:''},
    component: () => import('../views/Question')
  },
  {
    path: '/sending',
    meta: {title: 'お問い合わせ | のぞみクリニック築地',
      description:'のぞみクリニック築地は、築地駅から徒歩4分の場所にある総合医療クリニックです。一般内科、発熱外来、GLP-1受容体作動薬によるメディカルダイエット、プラセンタ治療など、多岐にわたる診療を提供しています。',
      keywords:'のぞみクリニック築地,一般内科,GLP-1受容体作動薬,発熱外来,エクソソーム,プラセンタ,土曜日診療可能'},
    component: () => import('../views/Sending')
  },
  // {
  //   path: "/pcrcn",
  //   meta: {title: 'PCR検査(中国渡航)｜のぞみクリニック築地'},
  //   component: () => import('../views/PcrCN')
  // },
  // {
  //   path: "/topic",
  //   meta: {title: 'トピックス｜のぞみクリニック築地'},
  //   component: () => import('../views/Topic')
  // },
  // {
  //   path: '/department/asp',
  //   meta: {title: '高山病予防｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Asp')
  // },
  // {
  //   path: '/department/dermatology',
  //   meta: {title: '腫瘍外科｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Dermatology')
  // },
  // {
  //   path: '/department/second',
  //   meta: {title: 'セカンドオピニオン｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Second')
  // },

  // {
  //   path: '/department/internal',
  //   meta: {title: '胃腸科｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Internal')
  // },
  // {
  //   path: '/department/kampo',
  //   meta: {title: '漢方治療｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Kampo')
  // },
  // {
  //   path: '/department/ReduceWeight',
  //   meta: {title: 'ダイエット｜のぞみクリニック築地'},
  //   component: () => import('../views/department/ReduceWeight')
  // },
  // {
  //   path: '/department/pediatrics',
  //   meta: {title: '小児科｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Pediatrics')
  // },
  // {
  //   path: '/department/placenta',
  //   meta: {title: 'プラセンタ｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Placenta')
  // },
  // {
  //   path: '/department/sas',
  //   meta: {title: '糖尿病治療｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Sas')
  // },
  // {
  //   path: '/department/checkup',
  //   meta: {title: '健康診断｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Checkup')
  // },
  {
    path: '/department/telemedicine',
    meta: {title: 'オンライン診療｜のぞみクリニック築地',
      description:'当院では、LOOMEの遠隔診療システムを導入しております。通常の来院予約及びオンライン診療の予約も承っております。',
      keywords:'オンライン診療,来院予約'},
    component: () => import('../views/department/Telemedicine_New')
  },
  {
    path: '/department/generalMedicine',
    meta: {title: '一般内科 | のぞみクリニック築地',
      description:'頭痛、めまい、かぜ症状、吐き気、腹痛、下痢など急性症状、便秘症、疲労感や倦怠感、花粉症などの一般的な疾患について診療します。',
      keywords:'頭痛,めまい,かぜ症状,吐き気,腹痛,下痢,疲労感,倦怠感,花粉症,高血圧,糖尿病,高脂血症,高尿酸血症（痛風）,便秘症'},
    component: () => import('../views/department/generalMedicine')
  },

  {
    path: '/department/fever',
    meta:{title:'発熱外来 | のぞみクリニック築地',
      description:'当院は東京都感染症診療協力医療機関で、「発熱外来」を行っております。',
      keywords:'発熱外来,新型コロナウイルス,PCR検査,抗原定性検査,のぞみクリニック築地'},
    component:() => import('../views/department/Fever')
  },
  {
    path: '/department/corona',
    meta:{title:'コロナ予防接種 | のぞみクリニック築地',
      description:'新型コロナワクチンの予防接種は、令和6年4月以降は、65歳以上の方および60～64歳で重症化リスクの高い方を対象とした定期接種に変わります。',
      keywords:'コロナ予防接種,新型コロナワクチン,のぞみクリニック築地'},
    component:() => import('../views/department/Corona')
  },
  {
    path: '/department/diet',
    meta:{title:'リベルサス | GLP-1受容体作動薬 | メディカルダイエット | のぞみクリニック築地',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/Diet')
  },
  {
    path:'/department/diet/price',
    meta:{title:'価格一覧 | 目指せ！リベルサス最安値 | のぞみクリニック築地',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/DietPrice')
  },
  {
    path:'/department/diet/guide',
    meta:{title:'診療からの流れ | のぞみクリニック築地',
      description:'当医院ではオンライン診療とご来院どちらでもGLP-1の処方を行います。',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/DietGuide')
  },
  {
    path:'/department/diet/faq',
    meta:{title:'よくある質問 | のぞみクリニック築地',
      description:'「見た目が変われば人生が変わる」「無理なく痩せたい」というお悩みにGLP-1受容体作動薬！のぞみクリニック築地',
      keywords:'リベルサス,GLP-1受容体作動薬,メディカルダイエット,のぞみクリニック築地'},
    component:() => import('../views/department/DietFAQ')
  },
  {
    path:'/department/placenta',
    meta:{title:'プラセンタ | 美肌効果 | のぞみクリニック築地',
      description:'プラセンタとは母親のお腹の中で赤ちゃんを守り、育てる役割をもった胎盤から抽出されるエキスのことです。',
      keywords:'プラセンタ,美肌効果,肝機能の改善,更年期障害の緩和,ホルモンバランスの調整'},
    component:() => import('../views/department/Placenta')
  },
  {
    path:'/department/stemsup',
    meta:{title:'エクソソーム | のぞみクリニック築地',
      description:'ヒト幹細胞培養上清液ステムサップ（StemSup）とは、ヒトの幹細胞を培養して、そこから分泌される活性物質を含む液のこと。',
      keywords:'StemSup,エクソソーム,脂肪由来幹細胞,歯髄由来幹細胞,臍帯由来幹細胞'},
    component:() => import('../views/department/Stemsup')
  },
  {
    path:'/department/cancer',
    meta:{title:'がん治療｜のぞみクリニック築地',
      description:'日本で著名な末期がん治療専門医である白川太郎医師は、「ヨウ素水」を用いた総合療法で注目を集めています。',
      keywords:'白川 太郎,ヨウ素水,HOPE HIKARI,末期がん治療'},
    component:() => import('../views/department/Cancer')
  },
]

const router = new VueRouter({
  //SEO需要该模式
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior(to,from,savedPosition) {
    return savedPosition ||
    {
        top: to.meta?.scrollTop || 0,
        left: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  
  from.meta?.scrollTop && (from.meta.scrollTop = window.scrollY)

  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.description) {
    if(!document.getElementById("route_description")){
      var ometa = document.createElement('meta')
      ometa.id = "route_description"
      ometa.name = "description"
      ometa.content = to.meta.description;
      document.getElementsByTagName('head')[0].appendChild(ometa)

      var ometa_og = document.createElement('meta')
      ometa_og.id = "route_description_og"
      ometa_og.name = "og:description"
      ometa_og.content = to.meta.description;
      document.getElementsByTagName('head')[0].appendChild(ometa)
    }
  }
  if (to.meta.keywords) {
    if(!document.getElementById("route_keywords")){  
      var ometa = document.createElement('meta')
      ometa.id = "route_keywords"
      ometa.name = "keywords"
      ometa.content = to.meta.keywords;
      document.getElementsByTagName('head')[0].appendChild(ometa)

      var ometa_og = document.createElement('meta')
      ometa_og.id = "route_keywords"
      ometa_og.name = "og:keywords"
      ometa_og.content = to.meta.keywords;
      document.getElementsByTagName('head')[0].appendChild(ometa_og)
    }
  }

  return next()
})

export default router
