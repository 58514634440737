<template>

</template>

<script>
export default {
  name: "Global",
  //host: 'http://192.168.1.14:39457',
  //host: 'http://192.168.10.140:39457',
  //host: 'http://13.112.41.19:39457',
  host: 'https://hope.api.rapha99.com'
}
</script>

<style scoped>

</style>
